import React, { memo } from 'react'
import { graphql, navigate } from 'gatsby'
import PageWrapper from '../components/PageWrapper'
import SectionTitle from '../components/SectionTitle'
import PropTypes from 'prop-types'
import SEO from '../components/SEO'
import Img from '../components/Img'
import Tags from '../components/Tags'
import SocialButtons from '../components/SocialButtons'

const WorkItem = ({ data }) => {
  if (data &&
    data.allWpWorkItem &&
    data.allWpWorkItem.edges &&
    data.allWpWorkItem.edges[0] &&
    data.allWpWorkItem.edges[0].node
  ) {
    const post = data.allWpWorkItem.edges[0].node
    const {
      slug,
      title,
      date,
      // modified,
      seo,
      acf_work: {
        featured,
        mainImage,
        clientName,
        projectDate,
        clientUrl,
        description,
        sections,
        enableSocialButtons,
        tags
      }
    } = post
    const _renderSections = () => {
      if (sections) {
        return sections.map((section, i) => {
          return (
            <div
              className="section"
              key={i}
              dangerouslySetInnerHTML={{ __html: section.section }}
            ></div>
          )
        })
      }
      return null
    }
    return (
      <PageWrapper>
        <SEO
          title={seo.title || title}
          description={description}
          url={`${data.site.siteMetadata.url}work/${slug}`}
          type="article"
          image={mainImage && mainImage.sourceUrl}
          description={seo.metaDesc}
          date={date}
        />
        <section className="workPost">
          <SectionTitle title="Work" subtitle="制作物" />
          <article>
            <div className="articleHeader">
              <div className="mainImage">
                <Img src={mainImage && mainImage.sourceUrl} alt={mainImage && mainImage.altText} title={mainImage && mainImage.title}/>
              </div>
              <h1 className="title large">{title}</h1>
              <h4 className="title medium">{description}</h4>
            </div>
            <div className="subTitle flex justify-between">
              <span className="date">{projectDate}</span>
              <Tags tags={tags} type="work" />
            </div>
            {
              enableSocialButtons ? <SocialButtons shareURL={`${data.site.siteMetadata.url}work/${slug}`} title={seo.title || title}/> : null
            }
            <div className="sections">
              { _renderSections() }
            </div>
          </article>
        </section>
      </PageWrapper>
    )
  } else {
    navigate('/404')
  }
}

WorkItem.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
}

export default memo(WorkItem)

export const postQuery = graphql`
  query workPostQuery($id: String!) {
    allWpWorkItem(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          uri
          slug
          title
          status
          date(formatString: "YYYY/MM/DD")
          modified(formatString: "YYYY/MM/DD")
          seo {
            title
            metaDesc
          }
          acf_work {
            featured
            clientName
            description
            projectDate
            clientUrl {
              target
              title
              url
            }
            sections {
              section
            }
            enableSocialButtons
            mainImage {
              title
              altText
              sourceUrl
            }
            tags: workTags {
              name
              slug
              termTaxonomyId
              description
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`
